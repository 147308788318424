module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-88385048-2","head":true,"anonymize":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"corrfox-wedding","short_name":"cfw","start_url":"/","background_color":"#6FD8B2","theme_color":"#6FD8B2","display":"minimal-ui","icon":"src/images/icon-white.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
